import { isVisible } from './is-visible';

/**
 * SelectorObserver
 *
 * Takes a selector and allows listeners
 * to subscribe to creation and shown events
 * for an element matching that selector.
 */

type SelectorListenerCallback = (elem: HTMLElement) => void;

export enum SelectorObserverMode {
  ON_SHOWN = 0,
  ON_FIRST_ENTERED_VIEWPORT = 1,
}

class SelectorObserver {
  selector: string;
  container: HTMLElement | Document;
  mode: SelectorObserverMode;

  elementObserver: MutationObserver;
  elementsAlreadySeen: Set<HTMLElement>;

  visibilityPollingInterval: ReturnType<typeof setInterval> | null;
  visibilityCheckElements: { elem: HTMLElement; wasVisible: boolean }[] | null;

  intersectionObserver: IntersectionObserver | null;

  constructor({
    selector,
    container,
    mode,
  }: {
    selector: string;
    container?: HTMLElement;
    mode: SelectorObserverMode;
  }) {
    this.selector = selector;
    this.container = container ?? document;
    this.mode = mode;

    this.elementObserver = new MutationObserver(() =>
      this.onContainerSubtreeChanged()
    );
    this.elementsAlreadySeen = new Set();

    this.visibilityPollingInterval = null;
    this.visibilityCheckElements = null;

    this.intersectionObserver = null;
  }

  listen(callback: SelectorListenerCallback) {
    if (this.mode === SelectorObserverMode.ON_SHOWN) {
      this.visibilityCheckElements = [];
      this.visibilityPollingInterval = setInterval(() => {
        if (this.visibilityCheckElements) {
          for (const entry of this.visibilityCheckElements) {
            if (!entry.elem.matches(this.selector)) {
              continue;
            }

            const visible = isVisible(entry.elem);
            if (visible && !entry.wasVisible) {
              callback(entry.elem);
            }
            entry.wasVisible = visible;
          }
        }
      }, 50);
    } else if (this.mode === SelectorObserverMode.ON_FIRST_ENTERED_VIEWPORT) {
      const alreadySeen = new Set<Element>();
      this.intersectionObserver = new window.IntersectionObserver(
        (entries) => {
          for (const entry of entries) {
            if (!entry.isIntersecting) {
              continue;
            }

            const elem = entry.target;
            if (alreadySeen.has(elem)) {
              continue;
            }

            if (elem instanceof HTMLElement) {
              callback(elem);
            }
            alreadySeen.add(elem);
          }
        },
        {
          root: this.container,
          threshold: 0,
        }
      );
    }

    this.startFindingCandidateElements();
  }

  disconnect() {
    if (this.visibilityPollingInterval) {
      clearInterval(this.visibilityPollingInterval);
      this.visibilityPollingInterval = null;
      this.visibilityCheckElements = null;
    }
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
      this.intersectionObserver = null;
    }
    this.elementObserver.disconnect();
  }

  private startFindingCandidateElements() {
    this.elementObserver.observe(this.container, {
      childList: true,
      subtree: true,
    });
    this.onContainerSubtreeChanged();
  }

  private onContainerSubtreeChanged() {
    const elems = this.container.querySelectorAll<HTMLElement>(this.selector);

    for (const elem of elems) {
      if (this.elementsAlreadySeen.has(elem)) {
        continue;
      }
      this.elementsAlreadySeen.add(elem);

      if (this.visibilityCheckElements) {
        this.visibilityCheckElements.push({ elem, wasVisible: false });
      }

      if (this.intersectionObserver) {
        this.intersectionObserver.observe(elem);
      }
    }
  }
}

export { SelectorObserver };
