/**
 * LoadingPage
 *
 * Provides a simpler interface that allows clients to easily
 * subscribe to loading events on the page.
 */

interface PageListener {
  onDomContentLoaded: () => void;
}

class LoadingPage {
  listeners!: PageListener[];

  constructor() {
    this.listeners = [];
  }

  addListener(listener: PageListener) {
    this.listeners.push(listener);
  }

  listen() {
    if (document.readyState !== 'loading') {
      this._fireDomContentLoadedEvents();
    } else {
      document.addEventListener('DOMContentLoaded', () => {
        this._fireDomContentLoadedEvents();
      });
    }
  }

  _fireDomContentLoadedEvents() {
    for (const listener of this.listeners) {
      if (listener.onDomContentLoaded) {
        listener.onDomContentLoaded();
      }
    }
  }
}

export { LoadingPage };
