import { handleLegacyManagePreordersEl } from '@purple-dot/web-components/self-service/self-service-web-component';
import { CartTools } from '../cart-tools';
import { CombinedBagCheckoutMethod } from '../checkout-method/combined-checkout-method';
import { ExpressCheckoutMethod } from '../checkout-method/express-checkout-method';
import { SeparateBagCheckoutMethod } from '../checkout-method/separate-bag-checkout-method';
import { PreorderStateDebugListener } from '../debug';
import { FeatureFlags } from '../feature-flags';
import { PurpleDotConfig } from '../purple-dot-config';
import { AddToCartForm } from '../shopify-theme/add-to-cart-form';
import { ShopifyThemeListener } from '../shopify-theme/shopify-theme';
import { WaitlistAvailability } from '../waitlist-availability';
import { ButtonImpressions } from './button-impressions';
import { HiddenElements } from './hidden-elements';
import { InternalAnalytics } from './internal-analytics';
import { OpenBoxIntegration } from './open-box-integration';
import { PreorderElements } from './pre-order-elements';
import { PreorderAddToCartForm } from './preorder-add-to-cart-form';
import { QuickAddFromCollectionItem } from './quick-add-from-collection-item';
import { SoldOutElements } from './sold-out-elements';
import { StyleElements } from './style-elements';
import { WaitlistEventForwarder } from './waitlist-event-forwarder';
import { WaitlistInfoRepositioner } from './waitlist-info-listener';

class PurpleDotIntegration {
  themeListeners: ShopifyThemeListener[];

  constructor({
    shopifyApi,
    waitlistAvailability,
    config,
    checkoutMethod,
    locale,
    cartTools,
    featureFlags,
  }: {
    shopifyApi: any;
    waitlistAvailability: WaitlistAvailability;
    config: PurpleDotConfig;
    checkoutMethod:
      | CombinedBagCheckoutMethod
      | SeparateBagCheckoutMethod
      | ExpressCheckoutMethod;
    locale: string;
    cartTools: CartTools;
    featureFlags: FeatureFlags;
  }) {
    const commonItems: ShopifyThemeListener = {
      onPageLoaded() {
        handleLegacyManagePreordersEl({
          apiKey: config.apiKey,
          email: config.customer?.email,
          token: config.customer?.token,
        });
      },

      async onNewAddToCartForm(atcForm: AddToCartForm) {
        const addToCartForm = new PreorderAddToCartForm({
          waitlistAvailability,
          atcForm,
        });

        const hideElements: string[] = [];
        if (config.pdp?.hide) {
          hideElements.push(...config.pdp.hide);
        }
        if (config.plp?.hide) {
          hideElements.push(...config.plp.hide);
        }
        addToCartForm.addListener(
          new HiddenElements({
            atcForm,
            hideElements,
            showElements: config.pdp?.show,
            waitlistAvailability,
          })
        );

        if (config.pdp?.styles) {
          addToCartForm.addListener(
            new StyleElements(atcForm, waitlistAvailability, config.pdp.styles)
          );
        }

        addToCartForm.addListener(
          new SoldOutElements({ waitlistAvailability, atcForm })
        );

        addToCartForm.addListener(
          new PreorderElements(atcForm, locale, {
            hideOnSoldOut: config.hideOnSoldOut,
            updateAddToCartButton: config.pdp?.updateAddToCartButton,
            waitlistInfoTargetSelector: config.pdp?.waitlistInfoTargetSelector,
            dispatchDateTargetSelector: config.pdp?.dispatchDateTargetSelector,
            brandingTargetSelector: config.pdp?.brandingTargetSelector,
          })
        );
        addToCartForm.addListener(checkoutMethod.getAddToCartListener());
        addToCartForm.addListener(new ButtonImpressions());

        addToCartForm.addListener(new PreorderStateDebugListener());

        // If no custom target selector for PD waitlist info is provided, then ensure that
        // the waitlist info is always repositioned to be next to the add to cart button
        if (!config.pdp?.waitlistInfoTargetSelector) {
          addToCartForm.addListener(new WaitlistInfoRepositioner());
        }

        await addToCartForm.mount();
      },
    };

    const methodSpecificListeners: ShopifyThemeListener[] = [];
    if (checkoutMethod instanceof CombinedBagCheckoutMethod) {
      methodSpecificListeners.push(cartTools);
    }

    this.themeListeners = [
      new QuickAddFromCollectionItem({
        waitlistAvailability,
        plpHide: config.plp?.hide,
        onCollectionItemOnPreorder: config.plp?.onCollectionItemOnPreorder,
        ...config.plp?.quickAdd,
      }),
      new WaitlistEventForwarder({
        waitlistAvailability,
        onProductPageWithWaitlist: config.onProductPageWithWaitlist,
      }),
      ...methodSpecificListeners,
      new InternalAnalytics(shopifyApi, featureFlags),
      commonItems,
      checkoutMethod.getThemeListener(),
      new OpenBoxIntegration(),
    ];
  }

  getThemeListeners() {
    return this.themeListeners;
  }
}

export { PurpleDotIntegration };
