import getDeviceId from '@purple-dot/browser-sdk/src/device-id';
import { GetWaitlistsResponseItem } from '@purple-dot/main/src/presentation-layer/custom-server/api/public/views/get-waitlists-response';
import { CartTools } from '../cart-tools';
import { formDataFromSubmit } from '../form-data-from-submit';
import { PurpleDotConfig } from '../purple-dot-config';
import { getCartFormConfig } from '../purple-dot-integration/cart-form';
import { PreorderStateListener } from '../purple-dot-integration/preorder-add-to-cart-form';
import { ShopifyProduct } from '../shopify-api';
import { AddToCartForm } from '../shopify-theme/add-to-cart-form';
import { ShopifyThemeListener } from '../shopify-theme/shopify-theme';
import { WaitlistAvailability } from '../waitlist-availability';
import { VariantSelectors } from './variant-selectors';

class CombinedCheckoutThemeListener implements ShopifyThemeListener {
  cartTools: CartTools;
  integrationPoints: PurpleDotConfig;
  waitlistAvailability: WaitlistAvailability;
  cartLink: string;
  redirectToCart: (e: Event) => void;
  cartForms: HTMLElement[] = [];

  constructor({
    cartTools,
    waitlistAvailability,
    integrationPoints,
  }: {
    cartTools: CartTools;
    waitlistAvailability: WaitlistAvailability;
    integrationPoints: PurpleDotConfig;
  }) {
    this.cartTools = cartTools;
    this.waitlistAvailability = waitlistAvailability;
    this.integrationPoints = integrationPoints;

    this.cartLink = window?.Shopify?.routes?.root
      ? `${window.Shopify.routes.root}cart`
      : '/cart';
    this.redirectToCart = (e) => {
      e.stopImmediatePropagation();
      e.preventDefault();

      window.location.href = this.cartLink;
    };
  }

  async onNewCheckoutButton(element: HTMLElement) {
    if (await this.cartTools.cartHasPreorderItems()) {
      element.addEventListener('click', this.redirectToCart, { capture: true });
    } else {
      element.removeEventListener('click', this.redirectToCart, {
        capture: true,
      });
    }
  }

  async onNewCheckoutLink(element: HTMLElement, hasVisibleCartLink: boolean) {
    if (!(await this.cartTools.cartHasPreorderItems())) {
      return;
    }

    if (!hasVisibleCartLink && element instanceof HTMLAnchorElement) {
      element.href = this.cartLink;
      const mutationObserver = new MutationObserver(() => {
        if (element.href !== this.cartLink) {
          element.href = this.cartLink;
        }
      });
      mutationObserver.observe(element, {
        attributes: true,
        attributeFilter: ['href'],
      });
    } else {
      element.style.display = 'none';
    }
  }

  async onNewCheckoutOrPaymentElement(element: HTMLElement) {
    if (!(await this.cartTools.cartHasPreorderItems())) {
      return;
    }

    element.style.display = 'none';
  }

  onNewStickyBar(bar: HTMLElement) {
    if (isCartPage()) {
      const barHeight = bar.getBoundingClientRect().height;
      const style = document.createElement('style');
      style.id = 'pd-checkout-position-adjustment';
      style.innerHTML = `
        #checkout-iframe {
          top: ${barHeight}px !important;
          height: calc(100% - ${barHeight}px) !important;
        }
      `;
      document.head.appendChild(style);
    }
  }

  async onNewCartForm(element: HTMLElement) {
    if (!this.cartForms.includes(element)) {
      this.cartForms.push(element);
    }
    await this.handleCartForm(element);
  }

  onAddToCart(
    [input, init]: [input: string | URL, init?: RequestInit],
    onComplete: Promise<unknown>
  ): Promise<[input: string | URL, init?: RequestInit]> {
    void onComplete.then(async () => {
      await Promise.allSettled(
        this.cartForms.map((e) => this.handleCartForm(e))
      );
    });

    return Promise.resolve([input, init]);
  }

  private async handleCartForm(element: HTMLElement) {
    // Element detached from DOM, do nothing
    if (!element.parentElement) {
      return;
    }

    this.hideLineItemProperties(element);

    /*
      Hide dynamic checkout options and klarna until we
      know the updated state of the cart and then re-show
      them afterwards
    */
    const elementsToHide = element.querySelectorAll<HTMLElement>(
      '[data-shopify="dynamic-checkout-cart"], .cart__klarna, square-placement'
    );

    const oldStyles: string[] = [];
    for (const elem of elementsToHide) {
      oldStyles.push(elem.style.display);
      elem.style.display = 'none';
    }

    if (!(await this.cartTools.cartHasPreorderItems())) {
      elementsToHide.forEach((elem, idx) => {
        elem.style.display = oldStyles[idx] ?? '';
      });

      return;
    }

    if (!isCartPage()) {
      let submitButton = element.querySelector<HTMLButtonElement>(
        'button[type="submit"], .zrx-cart-checkout-btn, .rebuy-cart__checkout-button, .rebuy-cart__flyout-actions > .rebuy-button'
      );

      if (!submitButton && element.tagName === 'FORM' && element.id) {
        submitButton = document.querySelector(
          `[type="submit"][form="${CSS.escape(element.id)}"]`
        );
      }

      element.onsubmit = (event) => {
        const form = event.target as HTMLFormElement;
        const formData = formDataFromSubmit(event);
        if (formData.get('checkout') || form.action.includes('checkout')) {
          event.preventDefault();
          window.location.href = this.cartLink;
        }
      };

      if (!submitButton || element.querySelector('.pd-cloned-button')) {
        return;
      }

      const clonedButton = submitButton.cloneNode(true) as HTMLButtonElement;
      clonedButton.name = '';
      clonedButton.type = 'button';
      clonedButton.onclick = () => {
        window.location.href = this.cartLink;
      };
      clonedButton.classList.add('pd-cloned-button');
      clonedButton.style.pointerEvents = 'auto';

      submitButton.style.display = 'none';
      submitButton.classList.add('pd-hide');
      submitButton.parentElement?.insertBefore(
        clonedButton,
        submitButton.nextSibling
      );
    }
  }

  async onNewAddToCartForm(atcForm: AddToCartForm) {
    const handle = atcForm.getHandle();
    if (!handle) {
      return;
    }

    const variantSelectorConfig = this.integrationPoints.pdp?.variantSelector;

    if (variantSelectorConfig) {
      const waitlist = await this.waitlistAvailability.getWaitlist({ handle });
      const variantSelectors = new VariantSelectors({
        config: variantSelectorConfig,
        waitlist: waitlist || undefined,
      });
      variantSelectors.enableSelectors(atcForm);
    }
  }

  /**
   * Hide 'Purple Dot Pre-order' and 'Purple Dot Payment Plan' keys
   * in line item properties. Ensure properties prefixed with '_' are
   * hidden entirely.
   */
  private hideLineItemProperties(element: HTMLElement) {
    const checkForLineItemPropertiesToHide = () => {
      const cartFormConfig = getCartFormConfig(element, this.integrationPoints);

      if (cartFormConfig) {
        const lineItemProperties = element.querySelectorAll<HTMLElement>(
          cartFormConfig.propertySelector
        );

        for (const property of lineItemProperties) {
          const keysToHide = [
            'Purple Dot Pre-order',
            'Purple Dot Payment Plan',
          ];

          for (const key of keysToHide) {
            if (property.textContent?.includes(key)) {
              cartFormConfig.onNewPropertyKeyToHide(property, key);
            }
          }

          const rowsToHide = ['__pdDebug', '__releaseId'];

          for (const row of rowsToHide) {
            if (property.textContent?.includes(row)) {
              property.style.display = 'none';
            }
          }
        }
      }
    };

    checkForLineItemPropertiesToHide();

    new MutationObserver(checkForLineItemPropertiesToHide).observe(element, {
      subtree: true,
      childList: true,
      characterData: true,
    });
  }
}

class CombinedCheckoutAddToCartListener implements PreorderStateListener {
  /*
    Disable the add to cart button
    before we have loaded data for the button
  */
  onMount() {
    return;
  }

  onPreorder(
    _product: ShopifyProduct,
    waitlist: GetWaitlistsResponseItem,
    _variantId: number,
    addToCartForm: AddToCartForm
  ) {
    this._upsertPreorderProperties(addToCartForm, waitlist);
  }

  inStock(
    _product: ShopifyProduct,
    _waitlist: GetWaitlistsResponseItem | null,
    _variantId: number,
    addToCartForm: AddToCartForm
  ) {
    this._removePreorderProperties(addToCartForm);
  }

  soldOut(
    _product: ShopifyProduct,
    _waitlist: GetWaitlistsResponseItem | null,
    _variantId: number,
    addToCartForm: AddToCartForm
  ) {
    this._removePreorderProperties(addToCartForm);
  }

  unknownState(_variantId: number, addToCartForm: AddToCartForm) {
    const addToCartButton = addToCartForm.getAddToCartButton();

    if (addToCartButton) {
      addToCartButton.disconnect();
    }

    this._removePreorderProperties(addToCartForm);
  }

  noVariantSelected() {
    return;
  }

  _upsertPreorderProperties(
    addToCartForm: AddToCartForm,
    waitlist: GetWaitlistsResponseItem
  ) {
    const properties = addToCartForm.getLineItemProperties();
    properties.upsert('__pdDebug', getDeviceId().deviceId);
    properties.upsert('__releaseId', waitlist.id);
    if (waitlist.display_dispatch_date) {
      properties.upsert('Purple Dot Pre-order', waitlist.display_dispatch_date);
    }
    if (waitlist.payment_plan_descriptions?.short) {
      properties.upsert(
        'Purple Dot Payment Plan',
        waitlist.payment_plan_descriptions.short
      );
    }
  }

  _removePreorderProperties(addToCartForm: AddToCartForm) {
    const properties = addToCartForm.getLineItemProperties();
    properties.remove('__releaseId');
    properties.remove('Purple Dot Pre-order');
    properties.remove('Purple Dot Payment Plan');
  }
}

export class CombinedBagCheckoutMethod {
  cartTools: CartTools;
  integrationPoints: PurpleDotConfig;
  waitlistAvailability: WaitlistAvailability;

  constructor({
    cartTools,
    integrationPoints,
    waitlistAvailability,
  }: {
    cartTools: CartTools;
    waitlistAvailability: WaitlistAvailability;
    integrationPoints: PurpleDotConfig;
  }) {
    this.cartTools = cartTools;
    this.integrationPoints = integrationPoints;
    this.waitlistAvailability = waitlistAvailability;
  }

  getThemeListener() {
    return new CombinedCheckoutThemeListener({
      cartTools: this.cartTools,
      waitlistAvailability: this.waitlistAvailability,
      integrationPoints: this.integrationPoints,
    });
  }

  getAddToCartListener() {
    return new CombinedCheckoutAddToCartListener();
  }
}

function isCartPage() {
  return window.location.pathname.includes('/cart');
}
