import { EventForwardingConfig } from './event-forwarding';
import type { FlagConfig } from './feature-flags';
import {
  NewEndpointPreorderState,
  ProductPreorderState,
} from './purple-dot-integration/backend';
import type { StyleConfiguration } from './purple-dot-integration/style-elements';

export enum CheckoutMethodEnum {
  CombinedCart = 'COMBINED_CART',
  Express = 'EXPRESS',
  SeparateCart = 'SEPARATE_CART',
}

type TranslationKey = 'addToCart' | 'preorderCTA' | 'soldOut';

export interface PurpleDotConfig {
  hostURL: string | null;
  apiKey: string;
  variantStockMap?: Record<string, number>; // no longer in use, kept for backward compatibility
  checkoutMethod?: CheckoutMethodEnum;
  shopifyCart: any;
  shopifyCartString?: string;
  shopifyCartSignature?: string;
  discountCode?: string | null;
  currency: string;
  locale?: string;
  enableNotifyMe: boolean;
  useAvailable?: boolean;
  cart?: {
    selector?: string;
    placeElement: (cartEl: HTMLElement, cartLink: Element) => HTMLElement;
    fill?: string;
    strokeWidth?: string;
    width?: string;
    height?: string;
    padding?: string;
    matchFill?: string;
  };
  pdp?: {
    variantSelector?: VariantSelectorConfig;
    productPageSelector?: string;
    selectedVariantSelector?: string;
    addToCartButtonSelector?: string;
    waitlistInfoTargetSelector?: string;
    dispatchDateTargetSelector?: string;
    brandingTargetSelector?: string;
    updateAddToCartButton?: boolean;
    hide?: string[];
    show?: string[];
    styles?: StyleConfiguration;
  };
  plp?: {
    quickAdd: {
      title: string;
      newTitle: string;
      sizeSelector: string;
      buttonSelector: string;
      disabledClass: string;
      removeAttributes: RemoveAttribute[];
      addWaitlistInfo?: boolean;
    };
    collectionItemSelector?: string;
    onCollectionItemOnPreorder?: OnCollectionItemOnPreorderCallback;
    hide?: string[];
    includeDefaultCollectionItemSelectors?: boolean;
  };
  cartFormSelector?: CartFormSelectorConfig | string;
  cartForm?: CartFormConfig;

  checkoutButtonSelector?: string;
  onBeforePurpleDotCheckout?: (element?: HTMLElement) => Promise<void>;
  tracking?: EventForwardingConfig;
  supportedMarkets?: string[];
  onUnsupportedMarket?: () => void;
  enableWaitlists: () => boolean;
  // Not documented
  includeDefaultCollectionItemSelectors?: boolean;
  hideOnSoldOut?: boolean;
  onProductPageWithWaitlist?: any;
  translations?: Record<string, { [_K in TranslationKey]: string }>;
  customer?: {
    email?: string;
    token?: string;
  };
  prefillEmail?: string | null;
  prefillShippingAddress?: PrefillShippingAddress | null;
  checkoutLineItems?: {
    preorder_state: NewEndpointPreorderState | null;
  }[];
  flags?: FlagConfig[];
}

export interface PrefillShippingAddress {
  email: string;
  first_name: string;
  last_name: string;
  address1: string;
  address2?: string;
  city: string;
  province_code: string;
  zip: string;
  country_code: string;
  phone?: string;
}

export interface RemoveAttribute {
  selector: string;
  attributes: string[];
}

export interface VariantSelectorConfig {
  find: (addToCartForm: any) => NodeListOf<HTMLElement>;
  parentFind?: (addToCartForm: any) => HTMLElement;
  selector: string;
  disabledClass: string;
}

export interface CartFormSelectorConfig {
  ignoreDefault?: boolean;
  additionalSelectors?: string[];
}

export interface CartFormConfig {
  propertySelector?: string;
  onNewPropertyKeyToHide?: (element: HTMLElement, key: string) => void;
  onCartItemsUpdated?: ({
    cartForm,
    disallowCheckout,
  }: {
    cartForm: HTMLElement;
    disallowCheckout: boolean;
  }) => void;
}

export type OnCollectionItemOnPreorderCallback = (
  gridItem: HTMLElement,
  waitlist: ProductPreorderState['waitlist']
) => void;

export function convertConfigIfLegacy(config: any) {
  const mapLegacyCartType: Record<string, CheckoutMethodEnum> = {
    COMBINED_BAG: CheckoutMethodEnum.CombinedCart,
    SEPARATE_BAG: CheckoutMethodEnum.SeparateCart,
    EXPRESS: CheckoutMethodEnum.Express,
  };
  if (
    config.cartType ||
    config.hideElements ||
    config.collectionItemQuickAdd ||
    config.collectionItemSelector
  ) {
    // Convert to new format
    const newConfig: PurpleDotConfig = {
      hostURL: config.hostURL,
      apiKey: config.apiKey,
      checkoutMethod: mapLegacyCartType[config.cartType],
      shopifyCart: config.shopifyCart,
      shopifyCartString: config.shopifyCartString,
      shopifyCartSignature: config.shopifyCartSignature,
      discountCode: config.discountCode,
      cart: config.express ? config.express.cartButton : null,
      enableNotifyMe: config.enableNotifyMe || false,
      pdp: {
        variantSelector: getVariantSelector(config),
        hide: config.hideElements,
        show: undefined,
      },
      plp: {
        quickAdd: config.collectionItemQuickAdd,
        collectionItemSelector: config.collectionItemSelector,
      },
      includeDefaultCollectionItemSelectors:
        config.includeDefaultCollectionItemSelectors,
      checkoutButtonSelector: config.checkoutButtonSelector,
      tracking: config.tracking,
      hideOnSoldOut: config.hideOnSoldOut,
      onProductPageWithWaitlist: config.onProductPageWithWaitlist,
      enableWaitlists: config.enableWaitlists,
      currency: config.currency,
      locale: config.locale,
      translations: config.translations,
      customer: config.customer,
    };

    return newConfig;
  }

  return config;
}

function getVariantSelector(config: any) {
  if (!config.separateBag && !config.express) {
    return undefined;
  }

  const variantSelector = config.separateBag
    ? config.separateBag.variantSelector
    : config.express.variantSelector;

  return {
    find: variantSelector.find,
    disabledClass: variantSelector.disabledClass,
    selector: variantSelector.target,
  };
}
