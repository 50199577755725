import { GetWaitlistsResponseItem } from '@purple-dot/main/src/presentation-layer/custom-server/api/public/views/get-waitlists-response';
import { ShopifyProduct } from '../shopify-api';
import { AddToCartForm } from '../shopify-theme/add-to-cart-form';
import { WaitlistAvailability } from '../waitlist-availability';
import { NewEndpointPreorderState } from './backend';
import { ElementsToHide, ElementsToShow } from './elements-to-hide';
import { PreorderStateListener } from './preorder-add-to-cart-form';

class HiddenElements implements PreorderStateListener {
  atcForm!: AddToCartForm;
  hideElements!: string[];
  showElements!: string[];
  elementsToHide!: ElementsToHide;
  elementsToShow!: ElementsToShow;
  private waitlistAvailability: WaitlistAvailability;

  constructor({
    atcForm,
    waitlistAvailability,
    hideElements = [],
    showElements = [],
  }: {
    atcForm: AddToCartForm;
    waitlistAvailability: WaitlistAvailability;
    hideElements?: string[];
    showElements?: string[];
  }) {
    this.atcForm = atcForm;
    this.waitlistAvailability = waitlistAvailability;

    this.elementsToHide = new ElementsToHide({
      atcForm,
      selectors: [
        '[class*="afterpay"]',
        'klarna-placement',
        'square-placement',
        'deliverr-tag-extended',
        '[class*="klarna"]',
        '.afterpay-paragraph',
        'afterpay-placement',
        'catch-callout',
        'shopify-payment-terms',
        '#BIS_trigger',
        '.shopify-payment-button',
        '[id*="sezzle"]',
        '[class*="sezzle"]',
        'super-product-callout',
        '[data-loyalty-pricing]',
        '[data-loyalty-drawer-toggle]',
        ...hideElements,
      ],
    });
    this.elementsToShow = new ElementsToShow({
      atcForm,
      selectors: showElements,
    });
  }

  onMount() {
    return;
  }

  onPreorder() {
    this.elementsToHide.hide();
    this.elementsToShow.show();
  }

  inStock() {
    this.elementsToHide.show();
    this.elementsToShow.hide();
  }

  soldOut() {
    this.elementsToHide.show();
    this.elementsToShow.hide();
  }

  unknownState() {
    this.elementsToHide.show();
    this.elementsToShow.hide();
  }

  noVariantSelected(
    _product: ShopifyProduct,
    _waitlist: GetWaitlistsResponseItem | null,
    _addToCartForm: AddToCartForm,
    state: NewEndpointPreorderState | undefined
  ) {
    if (state === NewEndpointPreorderState.OnPreorder) {
      this.onPreorder();
    }
  }
}

export { HiddenElements };
