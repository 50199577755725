/* eslint-disable class-methods-use-this */
import { GetWaitlistsResponseItem } from '@purple-dot/main/src/presentation-layer/custom-server/api/public/views/get-waitlists-response';
import { ShopifyProduct } from '../shopify-api';
import { AddToCartForm } from '../shopify-theme/add-to-cart-form';
import type { WaitlistAvailability } from '../waitlist-availability';
import { NewEndpointPreorderState } from './backend';
import { PreorderStateListener } from './preorder-add-to-cart-form';

export interface StyleStateConfiguration {
  media?: string;
  selector: string;
  style: string;
}

export interface StyleConfiguration {
  preorder?: StyleStateConfiguration[];
  instock?: StyleStateConfiguration[];
  soldout?: StyleStateConfiguration[];
  unknown?: StyleStateConfiguration[];
}

export class StyleElements implements PreorderStateListener {
  private styleSheets: {
    [_key in keyof StyleConfiguration]: HTMLStyleElement[];
  };

  constructor(
    private atcForm: AddToCartForm,
    private waitlistAvailability: WaitlistAvailability,
    private styles: StyleConfiguration
  ) {
    this.styleSheets = {
      preorder: this.createStyles(styles.preorder),
      instock: this.createStyles(styles.instock),
      soldout: this.createStyles(styles.soldout),
      unknown: this.createStyles(styles.unknown),
    };
  }

  private createStyles(styles?: StyleStateConfiguration[]) {
    return (styles ?? []).map((style) => {
      const styleEl = document.createElement('style');

      if (style.media) {
        styleEl.innerHTML = `
          @media ${style.media} {
            ${style.selector} {
              ${style.style}
            }
          }
        `;
      } else {
        styleEl.innerHTML = `
          ${style.selector} {
            ${style.style}
          }
        `;
      }

      return styleEl;
    });
  }

  private changeState(state: keyof typeof this.styleSheets) {
    for (const [stateName, sheets] of Object.entries(this.styleSheets)) {
      if (stateName === state) {
        document.body.append(...sheets);
      } else {
        for (const el of sheets) {
          el.remove();
        }
      }
    }
  }

  onMount() {
    return;
  }

  onPreorder() {
    this.changeState('preorder');
  }

  inStock() {
    this.changeState('instock');
  }

  soldOut() {
    this.changeState('soldout');
  }

  unknownState() {
    this.changeState('unknown');
  }

  noVariantSelected(
    _product: ShopifyProduct,
    _waitlist: GetWaitlistsResponseItem | null,
    _addToCartForm: AddToCartForm,
    state: NewEndpointPreorderState | undefined
  ) {
    if (state === NewEndpointPreorderState.OnPreorder) {
      this.onPreorder();
    }
  }
}
