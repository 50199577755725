import {
  CustomisableTranslations,
  customiseTranslations,
} from './localization';
import type { PurpleDotConfig } from './purple-dot-config';

export function setupTranslations(
  translations: PurpleDotConfig['translations']
) {
  if (!translations) {
    return;
  }

  for (const [locale, localeTranslations] of Object.entries(translations)) {
    const renamedTranslations: CustomisableTranslations = {};

    for (const [key, value] of Object.entries({
      'add-to-cart': localeTranslations.addToCart,
      'pre-order-cta': localeTranslations.preorderCTA,
      'sold-out': localeTranslations.soldOut,
    })) {
      if (value) {
        renamedTranslations[key] = value;
      }
    }

    customiseTranslations({ [locale]: renamedTranslations });
  }
}
